import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import FrontPageLink from '../components/FrontPageLink'

const Training = () => (
  <Layout img={'2'}>
    <SEO
      title="Koulutukset ja muut palvelut"
      keywords={[
        'Ensiapukurssi',
        'Potilasasiamies',
        'Ensiapukoulutus',
        'hoitotyön luento',
        'Simulaatiokoulutus',
        'EA1',
        'EA2',
        'hätäEA-kurssi'
      ]}
    />
    <FrontPageLink />
    <h2>Koulutukset ja muut palvelut</h2>
    <h3>Koulutukset</h3>
    <p>
      Tarjoan erilaisia ensiapukoulutuksia, hoitotyön luentoja ja simulaatiokoulutuksia yrityksille ja yhteisöille.
      Koulutukset voidaan räätälöidä kohderyhmän tarpeiden mukaan.
    </p>
    <p>
      Esimerkkeinä ensiapukoulutuksista ovat erilaiset SPR:n koulutukset kuten EA1, EA2 tai hätäEA-kurssi. Tarjoan myös defibrilaattorin käyttökoulutuksia tai toiveiden mukaan räätälöityjä ensiapukursseja.
    </p>
    <p>
      Hoitoalan yksiköille tai oppilaitoksille tarjoan koulutuksia esimerkiksi lääkehoitoon, haavahoitoon tai erilaisiin
      sairauksiin liittyen asiakkaan toiveiden mukaan.
    </p>
    <p>
      Simulaatiokoulutukset sopivat erityisesti hoitoalan yksiköille. Simulaation avulla todellisia tilanteita voidaan
      opetella simulaation avulla. Esimerkkeinä simulaatiokoulutuksesta ovat erilaiset elvytyskoulutukset,
      lääkityspoikkeamat tai akuuttitilanteet vanhuspalveluissa.
    </p>
    <h3>Muut palvelut</h3>
    <p>Tarjoan potilasasiamiespalvelua hoitoalan yrityksille.</p>
    <p>
      Potilasasiamiehen tehtävä on neuvoa ja antaa tietoa potilaan asemasta ja oikeuksista. Potilasasiamies auttaa
      tarvittaessa potilasta selvittämään hoitoonsa liittyviä ongelmia hoitopaikassaan. Hän neuvoo ja tarvittaessa
      avustaa potilasta muistutuksen, kantelun tai potilasvahinkoilmoituksen tekemisessä.
    </p>
  </Layout>
)

export default Training
